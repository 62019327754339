<!-- // 申请退款页面 -->
<template>
    <div class="sld_apply_refund">
        <div
            class="apply_refund_top flex_row_end_center"
            @click="batchSelGoods"
            v-if="batchList.data && batchList.data.length > 0"
        >
            <img src="@/assets/order/add.png" alt="" />
            <span>{{ L["添加/编辑"] }}</span>
            <span>{{ L["（同订单商品可批量申请）"] }}</span>
            <!-- <span>（已选7件商品）</span> -->
        </div>
        <!-- 退款商品信息 start -->
        <refund-goods
            :refundOrderDetail="refundOrderDetail.data"
            v-if="batchGoods.data && batchGoods.data.length == 0"
            :AfterSaleType="AfterSaleType"
            :isChecked="isChecked"
            @onCheck="onCheck"
            :submitOBj="submitOBj"
            :itemTableData="itemTableData"
        ></refund-goods>
        <!-- 退款商品信息 end -->
        <div v-if="batchGoods.data && batchGoods.data.length > 0">
            <refund-goods
                :refundOrderDetail="batchGoodsItem"
                v-for="(batchGoodsItem, batchGoodsIndex) in batchGoods.data"
                :key="batchGoodsIndex"
            ></refund-goods>
        </div>
        <!-- 退款详细信息 start -->
        <div class="apply_refund_info">
            <!-- 退款金额 start -->
            <!-- <div class="refund_amount flex_row_start_center marginBottom20">
        <div class="refund_title">{{ L["退款金额"] }}：</div> -->
            <!-- 不可编辑 -->
            <!-- <div class="refund_amount_edit flex_row_start_center" v-if="
            orderState == 20 ||
            ((orderState == 30 || orderState == 40) && curCargoStatus == '0'||curApplyType != '0')
          ">
          <span class="refund_amount_price">￥{{ refundDetail.data.maxReturnMoney }}</span>
          <div class="refund_amount_tips">
            （{{ L["退款金额不可修改，最多"] }}¥{{
            refundDetail.data.maxReturnMoney
            }}
            {{
            refundDetail.data.returnExpressFee > 0
            ? "(" + L["含运费"] + refundDetail.data.returnExpressFee + ")"
            : "（" + L["不含运费"] + "）"
            }}）
          </div>
        </div> -->
            <!-- 可编辑 -->
            <!-- <div v-else class="flex_row_start_center">
          <div class="refund_amount_edit flex_row_start_center" v-if="!editAmountShow">
            <span class="refund_amount_price">￥{{ curEditRefundAmount }}</span>
            <span class="edit_price_btn" @click="editAmount">{{
              L["修改金额"]
              }}</span>
            <span>（{{ L["退款金额可修改，最多"] }}¥{{
              refundDetail.data.maxReturnMoney
              }}
              {{
              refundDetail.data.returnExpressFee > 0
              ? "(" + L["含运费"] + refundDetail.data.returnExpressFee + ")"
              : "（" + L["不含运费"] + "）"
              }}）</span>
          </div>
          <div class="edit_refund_amount flex_row_start_center" v-if="editAmountShow">
            <el-input v-model="curEditRefundAmount" :placeholder="L['请输入退款金额']" type="number" class="amount_input"
              @blur="handleBlur">
            </el-input>
            <span class="cancel_edit_amount" @click="cancelEditAmount">{{
              L["取消"]
              }}</span>
            <span class="ok_edit_amount" @click="okEditAmount">{{
              L["确定"]
              }}</span>
          </div>
          <div class="refund_amount_tips" v-if="editAmountShow">
            （{{ L["退款金额可修改，最多"] }}¥{{
            refundDetail.data.maxReturnMoney
            }}
            {{
            refundDetail.data.returnExpressFee > 0
            ? "(" + L["含运费"] + refundDetail.data.returnExpressFee + ")"
            : "（" + L["不含运费"] + "）"
            }}）
          </div>
        </div> -->
            <!-- </div> -->
            <!-- 退款金额 end -->
            <!-- 申请件数 start -->
            <!-- <div class="apply_number flex_row_start_center marginBottom20">
        <div class="refund_title">{{ L["申请件数"] }}：</div> -->
            <!-- 不可修改 -->
            <!-- <div class="apply_number_con" v-if="
            orderState == 20 ||
            ((orderState == 30 || orderState == 40) && curApplyType == '0')
          ">
          {{ applyNum }}
        </div> -->
            <!-- 可修改 -->
            <!-- <el-input type="number" v-model="applyNum" :placeholder="L['请输入申请件数']" class="edit_apply_number"
          :disabled="batchGoods.data.length>1?true:false" @blur="handleApply" v-else>
        </el-input>
      </div> -->
            <!-- 申请件数 end -->
            <!-- 申请类型 start-->
            <!-- <div class="apply_type flex_row_start_center marginBottom20" v-if="orderState != 20">
        <div class="refund_title"><span>*</span>{{ L["申请类型"] }}：</div>
        <el-radio-group class="cargo_status_btn flex_row_start_center" v-model="curApplyType"
          @change="changeApplyType($event)">
          <el-radio :label="applyTypeItem.value" v-for="(applyTypeItem, applyTypeIndex) in applyTypeList.data"
            :key="applyTypeIndex">{{ applyTypeItem.content }}
          </el-radio>
        </el-radio-group>
      </div> -->
            <!-- 申请类型 end -->
            <!-- 货物状态 start -->
            <!-- <div class="cargo_status flex_row_start_center marginBottom20"
        v-if="(orderState == 30 || orderState == 40) && curApplyType == '0'">
        <div class="refund_title"><span>*</span>{{ L["货物状态"] }}：</div>
        <el-radio-group class="cargo_status_btn flex_row_start_center" v-model="curCargoStatus"
          @change="changeCargoStatus($event)">
          <el-radio :label="cargoStatusItem.value" v-for="(cargoStatusItem, cargoStatusIndex) in cargoStatuslList.data"
            :key="cargoStatusIndex">{{ cargoStatusItem.content }}
          </el-radio>
        </el-radio-group>
      </div> -->
            <!-- 货物状态 end -->
            <!-- 退款原因 start -->
            <div
                :class="AfterSaleType == '换货' ? 'online' : ''"
                :style="AfterSaleType !== '换货' ? 'margin-bottom: 20px;' : ''"
            >
                <p
                    style="
                        display: flex;

                        color: red;
                        font-size: 14px;
                    "
                >
                    <span class="refund_title">申请类型：</span>{{ AfterSaleType }}
                </p>
                <div style="display: flex; margin-left: 40px; align-items: center" v-if="AfterSaleType == '换货'">
                    <img
                        :src="isChecked ? CheckrdImg : ellipse"
                        :v-model="isChecked"
                        alt=""
                        @click="onCheck(true)"
                        style="width: 16px; height: 16px"
                    />
                    <div style="margin-left: 10px">线下邮寄</div>
                </div>
            </div>
            <div style="display: flex; align-items: center; margin-bottom: 20px" v-if="AfterSaleType !== '仅退款'">
                <span v-if="AfterSaleType == '退货退款'" class="refund_title"> 退货单号： </span>

                <div style="position: relative; width: 230px; display: flex" v-if="AfterSaleType == '退货退款'">
                    <el-input
                        :maxlength="12"
                        placeholder="请输入退货单号"
                        show-word-limit
                        type="input"
                        style="width: 230px"
                        v-model="submitOBj.returnNumber"
                        @change="returnNumberChange"
                    />

                    <span
                        class="clickBtn"
                        v-if="itemTableData.data.length > 0"
                        style="position: absolute; top: 14px; right: 10px"
                        @click="addDescImg()"
                        >查看</span
                    >

                    <i
                        class="el-icon-plus"
                        @click="addDescImg()"
                        v-else
                        style="
                            cursor: pointer;
                            font-size: 20px;
                            border: 1px dashed #c0ccda;
                            position: absolute;
                            top: 8px;
                            right: 10px;
                        "
                    ></i>
                </div>
                <div style="display: flex; margin-left: 40px; align-items: center" v-if="AfterSaleType !== '换货'">
                    <img
                        :src="isChecked ? CheckrdImg : ellipse"
                        :v-model="isChecked"
                        alt=""
                        @click="onCheck(true)"
                        style="width: 16px; height: 16px"
                    />
                    <div style="margin-left: 10px">线下邮寄</div>
                </div>
            </div>
            <div
                class="refund_reasons flex_row_start_center marginBottom20"
                v-if="cancelList.data && cancelList.data.length > 0"
            >
                <div class="refund_title">
                    <span>*</span>{{ AfterSaleType == "仅退款" ? "退款" : AfterSaleType }}原因：
                </div>
                <el-select
                    :placeholder="L['请选择退款原因']"
                    @change="changeCancel($event)"
                    v-model="curCancelReason"
                    id="elSelect"
                >
                    <el-option
                        v-for="cancelItem in cancelList.data"
                        :key="cancelItem.reasonId"
                        :label="cancelItem.content"
                        :value="cancelItem.reasonId"
                    >
                    </el-option>
                </el-select>
            </div>
            <!-- 退款原因 end -->
            <!-- 退款说明 start -->
            <div class="refund_instruction flex_row_start_start marginBottom20">
                <div class="refund_title">{{ L["问题描述"] }}：</div>
                <el-input
                    type="textarea"
                    v-model="refundInstruction"
                    maxlength="200"
                    show-word-limit
                    style="width: 780px"
                    :placeholder="L['请输入问题描述(选填)']"
                ></el-input>
            </div>
            <!-- 退款说明 end -->
            <!-- 上传凭证 start -->
            <div class="upload_voucher flex_row_start_start marginBottom20">
                <div class="refund_title">{{ L["上传凭证"] }}：</div>
                <div>
                    <div class="upload_count">
                        总共上传<span style="color: red">{{ upLoadFile.data.length }}</span
                        >/5张图片
                    </div>
                    <div class="upload">
                        <el-upload
                            :action="uploadImgUrl"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            limit="5"
                            :data="upload_data"
                            name="file"
                            :on-success="uploadImg"
                            :on-exceed="exceedHandle"
                            id="elUpload"
                        >
                            <template #default>
                                <i class="el-icon-plus"></i>
                            </template>
                        </el-upload>
                        <el-dialog v-model="dialogVisible" lock-scroll="false" top="10vh" width="60%">
                            <div style="overflow: auto; width: 100%; display: flex; justify-content: center">
                                <img :src="dialogImageUrl" alt="" style="max-height: 700px" />
                            </div>
                        </el-dialog>
                    </div>
                </div>
            </div>
            <!-- 上传凭证 end -->
        </div>
        <!-- 退款详细信息 end -->
        <div class="apply_refund_btn" @click="submitApplyRefund">
            {{ L["确定"] }}
        </div>

        <!-- 批量选择商品的弹框 start -->
        <el-dialog
            width="620px"
            v-model="batchSelModel"
            append-to-body
            :title="L['批量售后商品选择']"
            lock-sroll="false"
        >
            <div class="batch_selModel">
                <div class="batch_default">
                    <div class="batch_default_pre flex_row_start_center">
                        <img src="@/assets/order/sel.png" alt="" class="batch_default_sel" />
                        <div
                            :style="{
                                backgroundImage: 'url(' + defaultGoods.data.goodsImage + ')'
                            }"
                            class="batch_default_img"
                        ></div>
                        <div style="width: 100%">
                            <div class="batch_default_des">
                                <p>{{ defaultGoods.data.goodsNameJoin }}</p>
                                <p>{{ defaultGoods.data.specValues }}</p>
                            </div>
                            <div class="flex_row_between_center">
                                <div>单价：¥{{ defaultGoods.data.goodsPrice }}</div>
                                <div style="width: 130px">下单数量：{{ defaultGoods.data.goodsNum }}</div>
                            </div>
                        </div>
                        <!-- <el-input-number v-model="defaultGoods.data.editNum" @change="handleEditDefaultNum" :min="1"
              :max="defaultGoods.data.goodsNum > 999 ? 999 : defaultGoods.data.goodsNum" label="描述文字"
              :class="edit_batch_num" :disabled="orderState==20"></el-input-number> -->
                    </div>
                </div>
                <div class="batch_default">
                    <div
                        class="batch_default_pre flex_row_start_center"
                        v-for="(batchGoodsItem, batchGoodsIndex) in batchGoodsList.data"
                        :key="batchGoodsIndex"
                    >
                        <img
                            src="@/assets/order/sel.png"
                            alt=""
                            class="batch_default_sel"
                            v-if="batchGoodsItem.sel"
                            @click="selGoods(batchGoodsItem.skuCode)"
                        />
                        <img
                            src="@/assets/order/no_sel.png"
                            alt=""
                            class="batch_default_sel"
                            v-if="!batchGoodsItem.sel"
                            @click="selGoods(batchGoodsItem.skuCode)"
                        />
                        <div
                            :style="{
                                backgroundImage: 'url(' + batchGoodsItem.goodsImage + ')'
                            }"
                            class="batch_default_img"
                        ></div>
                        <div style="width: 100%">
                            <div class="batch_default_des">
                                <p>{{ batchGoodsItem.goodsNameJoin }}</p>
                                <p>{{ batchGoodsItem.specValues }}</p>
                            </div>
                            <div class="flex_row_between_center">
                                <div>单价：¥{{ batchGoodsItem.goodsPrice }}</div>
                                <div style="width: 130px">下单数量：{{ batchGoodsItem.goodsNum }}</div>
                            </div>
                        </div>
                        <!-- <el-input-number v-model="batchGoodsItem.editNum" @change="handleEditBatchNum" :min="1"
              :max="batchGoodsItem.goodsNum > 999 ? 999 : batchGoodsItem.goodsNum" label="描述文字"
              :class="edit_batch_num" :disabled="orderState==20"></el-input-number> -->
                    </div>
                </div>
            </div>
            <div class="batch_model_btn flex_row_center_center">
                <span @click="batchCancel">{{ L["取消"] }}</span>
                <span @click="batchOk" style="margin-left: 26px">{{ L["确定"] }}</span>
            </div>
        </el-dialog>
        <!-- 批量选择商品的弹框 end -->
        <div class="imgMessage">
            <el-dialog
                v-model="descImgVisible"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                title="图片信息"
                width="820px"
            >
                <div>
                    <el-upload
                        limit="5"
                        v-model:file-list="itemTableData.data"
                        :action="uploadImgUrl"
                        :headers="{ Authorization: 'Bearer ' + access_token }"
                        list-type="picture-card"
                        :before-upload="beforeUpload"
                        :on-success="handlePictureCardSuccess(itemTableData.data)"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemoves"
                        :on-exceed="exceedHandle"
                        id="imgUpload"
                    >
                        <!--'disabled-upload': itemTableData.data.state == 3,-->
                        <i class="el-icon-plus"></i>
                    </el-upload>

                    <el-dialog v-model="dialogVisibleFull">
                        <img w-full :src="dialogImageUrls" alt="Preview Image" />
                    </el-dialog>
                </div>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="handleCloseDesc">关闭</el-button>
                        <el-button type="primary" @click="handleConfirmDesc">确认</el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, watchEffect } from "vue";
import { ElMessage, ElInput } from "element-plus";
import RefundGoods from "../../../components/RefundGoods";
import { apiUrl } from "../../../utils/config";
import { useRoute, useRouter } from "vue-router";
import CheckrdImg from "../../../assets/iconImages/Checked@2x.png";
import ellipse from "../../../assets/iconImages/ellipse@2x.png";
import { apiUrlSub } from "../../../utils/config";
export default {
    components: {
        RefundGoods,
        ElInput
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const route = useRoute();
        const router = useRouter();
        const refundOrderDetail = reactive({ data: {} }); //退款商品信息
        const upload_data = reactive({
            source: "afterSale"
        });
        const cargoStatuslList = reactive({
            data: [
                //货物状态
                {
                    content: L["未收到货"],
                    value: "0",
                    cargoStatusCurId: 0
                },
                {
                    content: L["已收到货"],
                    value: "1",
                    cargoStatusCurId: 1
                }
            ]
        });
        const applyTypeList = reactive({
            //申请类型列表
            data: [
                {
                    content: L["仅退款"],
                    value: "0"
                },
                {
                    content: L["退款退货"],
                    value: "1"
                }
            ]
        });
        const curCargoStatus = ref("0"); //货物状态，默认选中第一个（未收到货）
        const returnAmount = ref(""); //退款金额
        const applyNum = ref(1); //申请件数
        const refundDetail = reactive({ data: {} }); //退款详情
        const cancelList = reactive({ data: [] }); //取消原因列表
        const curCancelReason = ref(""); //当前选中的取消原因
        const curCancelReasonId = ref(-1); //当前选中的取消原因的id
        const refundInstruction = ref(""); //退款说明
        //const uploadImgUrl = apiUrl + "v3/oss/common/upload"; //图片上传路径
        const orderState = ref(20); //orderState:订单状态，20，待发货 30,待收货
        const serviceType = ref("0"); //选择服务类型 0：仅退款 1，退款退货
        const cargoStatusCurId = ref(0); //当前已选择的货物状态id
        const curEditRefundAmount = ref("");
        const batchSelModel = ref(false); //批量申请售后弹框
        const curApplyType = ref(1); //当前申请类型 1：仅退款 2：退款退货 3.换货
        const batchList = reactive({ data: [] }); //批量售后商品列表
        const defaultGoods = reactive({ data: {} }); //默认选中的商品
        const batchGoodsList = reactive({ data: [] }); //同订单其他的商品
        const batchGoods = reactive({ data: [], ids: [] }); //批量选择后的商品列表
        const editAmountShow = ref(false); //修改金额是否显示
        const skuCode = ref(""); //订单商品id
        const orderCode = ref(""); //订单code
        const dialogVisible = ref(false); //图片预览弹框是否显示
        const dialogImageUrl = ref(""); //图片预览图片地址
        const upLoadFile = reactive({ data: [] }); //用于传给后端的文件列表
        const AfterSaleType = ref(""); //售后类型
        const isChecked = ref(true);
        const submitOBj = ref({
            returnNumber: "",
            logisticsImage: ""
        });
        const itemTableData = reactive({ data: [] }); //table中单项数据
        const itemAddImgData = reactive({ data: [] }); //table中单项新增图片数据
        const itemDelImgData = reactive({ data: [] }); //table中单项删除图片数据
        const uploadImgUrl = apiUrlSub + "api/shopp/upload/oss"; //图片上传路径
        const access_token = localStorage.getItem("access_token");
        const dialogVisibleFull = ref(false);
        const dialogImageUrls = ref("");
        const descImgVisible = ref(false); //备注图片上传弹窗
        onMounted(() => {
            orderState.value = route.query.orderState;
            orderCode.value = route.query.orderSn;
            skuCode.value = route.query.skuCode;
            AfterSaleType.value = route.query.type;

            getRefundOrderDetail();
        });
        watchEffect(() => {
            //监听申请件数的变化
            if (applyNum.value > refundDetail.data.number) {
                applyNum.value = refundDetail.data.number;
                ElMessage(L["不可超出最大申请数量"]);
            }
        });
        //获取售后订单货品详情
        const getRefundOrderDetail = () => {
            let params = {
                memberOrderCode: orderCode.value,
                skuCode: skuCode.value
            };
            proxy
                .$get("api/shopp/order/after/apply/sku", params)
                .then((res) => {
                    //原接口：v3/business/front/after/sale/apply/getOrderProductDetail
                    if (res.code == 200) {
                        refundOrderDetail.data = res.data;
                        //refundOrderDetail.data.isChecked=true;
                        //refundOrderDetail.data.AfterSaleType=route.query.type;
                        //refundOrderDetail.data.returnNumber="";
                        //refundOrderDetail.data.logisticsImage="";
                        //refundOrderDetail.data.logisticsImage="";
                        //refundOrderDetail.data.isOfflineMailing=""
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
                .then(() => {
                    getRefundDetail();
                    getCancelList();
                    getBatchOrderDetail();
                });
        };
        //获取计算售后退款信息，计算结果未此次最多可退金额
        const getRefundDetail = (type, Infos, num) => {
            let params = {};
            if (type == "batch") {
                params = {
                    orderSn: refundOrderDetail.data.orderSn,
                    orderProductInfos: Infos.join(",") //退换的订单货品列表，格式为：id1-num1,id2-num2...num为空时表示此订单货品全部退换
                };
            } else {
                //单个商品
                let orderProductInfos = "";
                orderProductInfos = refundOrderDetail.data.skuCode + "-" + (num || refundOrderDetail.data.goodsNum);
                params = {
                    orderSn: refundOrderDetail.data.orderSn,
                    orderProductInfos: orderProductInfos
                };
            }
            // proxy.$get("v3/business/front/after/sale/apply/countReturnMoney", params).then((res) => {
            //   if (res.state == 200) {
            //     let result = res.data;
            //     if(num){
            //       Object.keys(result).forEach(i=>{
            //         if(i != 'number' || !refundDetail.data.number){
            //           refundDetail.data[i] = result[i]
            //         }
            //       })
            //     }else{
            //       refundDetail.data = result;
            //     }
            //     returnAmount.value = "" + result.maxReturnMoney;
            //     curEditRefundAmount.value = refundDetail.data.maxReturnMoney;
            //     applyNum.value = "" + result.number;
            //   } else {
            //     ElMessage.error(res.msg);
            //   }
            // });
        };
        const editAmount = () => {
            editAmountShow.value = true;
        };
        //取消修改退款金额
        const cancelEditAmount = () => {
            curEditRefundAmount.value = refundDetail.data.maxReturnMoney;
            editAmountShow.value = false;
        };
        //确定修改退款金额
        const okEditAmount = () => {
            editAmountShow.value = false;
            if (curEditRefundAmount.value <= 0) {
                ElMessage(L["退款金额不可为0或者负值！"]);
                curEditRefundAmount.value = refundDetail.data.maxReturnMoney;
            } else if (curEditRefundAmount.value > refundDetail.data.maxReturnMoney) {
                ElMessage(L["不可超过最大退款金额！"]);
                curEditRefundAmount.value = refundDetail.data.maxReturnMoney;
            }
        };
        //获取退款原因
        const getCancelList = () => {
            // 参数：curApplyType.value 0:仅退款 1：退货退款
            // 参数: cargoStatusCurId.value 0 :未收到货 1：已收到货
            let type = 1;
            if (orderState.value == 20) {
                type = 1;
            } else {
                if (curApplyType.value == 1 && cargoStatusCurId.value == "0") {
                    //仅退款未收到货
                    type = 1;
                }
                if (curApplyType.value == 1 && cargoStatusCurId.value == "1") {
                    //仅退款已收货
                    type = 1;
                }
                if (curApplyType.value == 1) {
                    //退货退款
                    type = 1;
                }
            }
            let params = {
                reasonType:
                    AfterSaleType.value == "仅退款"
                        ? 1
                        : AfterSaleType.value == "退货退款"
                        ? 3
                        : AfterSaleType.value == "换货"
                        ? 4
                        : "" //  原因类型 1=仅退款-未收货,2=仅退款-已收货,3=退款退货原因,4=换货原因, 5 = 订单取消原因
            };
            //原因类型：101-违规下架；102-商品审核拒绝；103-入驻审核拒绝；104-会员取消订单；105-仅退款-未收货；106-仅退款-已收货；107-退款退货原因；108-商户取消订单
            proxy.$get("api/shopp/after/reason/list", params).then((res) => {
                //原接口：/v3/system/front/reason/list
                if (res.code == 200) {
                    let result = res.data;
                    cancelList.data = result || [];
                } else {
                    ElMessage.error(res.msg);
                }
            });
        };
        //选择退款原因
        const changeCancel = (e) => {
            cancelList.data &&
                cancelList.data.map((cancelItem) => {
                    if (cancelItem.reasonId == e) {
                        curCancelReason.value = cancelItem.content;
                        curCancelReasonId.value = cancelItem.reasonId;
                    }
                });
        };
        //选择货物状态
        const changeCargoStatus = (e) => {
            curCargoStatus.value = e;
            curCancelReason.value = "";
            curCancelReasonId.value = "";
            cargoStatuslList.data.map((cargoStatusItem) => {
                if (cargoStatusItem.value == e) {
                    cargoStatusCurId.value = e;
                }
            });
            getCancelList();
        };
        //选择申请类型
        const changeApplyType = (e) => {
            curCancelReason.value = "";
            curCancelReasonId.value = "";
            applyTypeList.data &&
                applyTypeList.data.map((applyItem) => {
                    if (applyItem.value == e) {
                        curApplyType.value = e;
                    }
                });
            getCancelList();
        };
        //批量选择商品
        const batchSelGoods = () => {
            batchSelModel.value = true;
            defaultGoods.data.editNum = defaultGoods.data.currNum;
            batchGoodsList.data.forEach((item) => {
                if (batchGoods.ids.indexOf(item.goodsId) != -1) {
                    //item.sel = true;
                } else {
                    item.sel = false;
                }
                item.editNum = item.currNum;
            });
        };
        //获取售后商品选择的商品数据
        const getBatchOrderDetail = () => {
            let params = {
                memberOrderCode: orderCode.value
            };
            proxy.$get("api/shopp/order/after/apply/details", params).then((res) => {
                //原接口：v3/business/front/after/sale/apply/getOrderProductList
                if (res.code == 200) {
                    let result = res.data;
                    batchList.data = result;
                    // 主商品
                    defaultGoods.data = batchList.data[0];
                    defaultGoods.data.currNum = batchList.data[0].goodsNum;
                    defaultGoods.data.sel = true; //sel是否选中
                    // 同订单其他商品
                    batchList.data.splice(0, 1);
                    batchGoodsList.data = batchList.data;
                    batchGoodsList.data &&
                        batchGoodsList.data.map((batchGoodsItem) => {
                            batchGoodsItem.currNum = batchGoodsItem.goodsNum;
                            batchGoodsItem.sel = false;
                        });
                } else {
                    ElMessage.error(res.msg);
                }
            });
        };
        //批量售后，商品状态的选择
        const selGoods = (skuCode) => {
            batchGoodsList.data &&
                batchGoodsList.data.map((batchGoodsItem) => {
                    if (batchGoodsItem.skuCode == skuCode) {
                        batchGoodsItem.sel = !batchGoodsItem.sel;
                    }
                });
        };
        //批量售后，取消
        const batchCancel = () => {
            batchSelModel.value = false;
            // batchGoods.data = [];
            // getBatchOrderDetail();
        };
        //批量售后，确定
        const batchOk = () => {
            batchSelModel.value = false;
            defaultGoods.data.currNum = defaultGoods.data.editNum;
            //批量多个商品
            let batchSelList = [];
            let ids = [];

            batchSelList.push(defaultGoods.data);
            batchGoodsList.data.map((batchGoodsItem) => {
                //batchGoodsItem.AfterSaleType=route.query.type;
                //batchGoodsItem.returnNumber="";
                //batchGoodsItem.logisticsImage="";
                //batchGoodsItem.logisticsImage="";
                //batchGoodsItem.isOfflineMailing=""
                batchGoodsItem.currNum = batchGoodsItem.editNum;
                if (batchGoodsItem.sel) {
                    batchSelList.push(batchGoodsItem);
                    ids.push(batchGoodsItem.goodsId);
                }
            });
            batchSelList.forEach((batchSelItem) => {
                batchSelItem.infopre = batchSelItem.skuCode + "-" + batchSelItem.currNum;
            });
            let Infos = [];
            batchSelList.forEach((batchSelItem) => {
                Infos.push(batchSelItem.infopre);
            });
            //同订单其他商品
            batchGoods.data = batchSelList;
            batchGoods.ids = ids;
            batchGoodsList.data = batchList.data;

            getRefundDetail("batch", Infos);
        };
        //图片预览
        const handlePictureCardPreview = (response) => {
            dialogVisible.value = true;
            dialogImageUrl.value = response.url;
        };
        //上传图片
        const uploadImg = (response) => {
            console.log(response, 78687);
            if (response.code == 200) {
                upLoadFile.data.push(response.msg);
            }
            if (upLoadFile.data.length == 5) {
                document.getElementById("elUpload").children[1].style.display = "none";
            }
        };
        const handleRemove = (file) => {
            if (file.status == "success") {
                upLoadFile.data = upLoadFile.data.filter((item) => item != file.response.data.path);
            }
            if (upLoadFile.data.length < 5) {
                document.getElementById("elUpload").children[1].style.display = "inline-block";
            }
        };

        //上传图片，图片超出限制
        const exceedHandle = () => {
            ElMessage("最多上传5张！");
        };
        //确定提交申请
        const submitApplyRefund = () => {
            // console.log(batchGoods.data)
            // console.log(refundOrderDetail.data)
            if (curCancelReason.value == "") {
                ElMessage(L["请选择退款原因"]);
            } else {
                let productList = [];
                if (batchGoods.data.length == 0) {
                    //单个商品
                    let orderProduct = {};
                    orderProduct.skuCode = refundOrderDetail.data.skuCode;
                    orderProduct.returnNum =
                        refundOrderDetail.data.confirmTonnageNum && refundOrderDetail.data.confirmTonnageNum != 0
                            ? refundOrderDetail.data.confirmTonnageNum
                            : refundOrderDetail.data.goodsNum;
                    orderProduct.returnMoneyAmount = refundOrderDetail.data.goodsSumPrice;
                    productList.push(orderProduct);
                } else {
                    //批量申请
                    productList =
                        batchGoods.data &&
                        batchGoods.data.map(function (batchGoodsItem) {
                            return {
                                skuCode: batchGoodsItem.skuCode,
                                returnNum:
                                    batchGoodsItem.confirmTonnageNum && batchGoodsItem.confirmTonnageNum != 0
                                        ? batchGoodsItem.confirmTonnageNum
                                        : batchGoodsItem.goodsNum,
                                returnMoneyAmount: batchGoodsItem.goodsSumPrice
                            };
                        });
                }
                let params = {
                    memberOrderCode: orderCode.value,
                    afterType:
                        AfterSaleType.value == "仅退款"
                            ? 1
                            : AfterSaleType.value == "退货退款"
                            ? 3
                            : AfterSaleType.value == "换货"
                            ? 4
                            : "", //申请类型（售后服务单类型，1-仅退款单（需关联处理退款金额），2-换货单，3-退货退款单）
                    afterReason: curCancelReason.value, //售后原因
                    remarks: refundInstruction.value, //问题描述
                    afterVoucher: upLoadFile.data.join(","), //上传凭证图片,多个图片用英文逗号隔开
                    afterDetails: productList, //订单货品列表
                    goodsState: orderState.value == 40 ? 1 : 0 //货物状态
                    // finalReturnAmount: curEditRefundAmount.value, //退款金额
                };

                if (submitOBj.value.returnNumber !== "" || itemTableData.data.length !== 0 || isChecked.value == true) {
                    let imgArr = [];
                    itemTableData.data.forEach((item) => {
                        imgArr.push(item.url);
                    });
                    let str = /^\d{12}$/;
                    if (submitOBj.value.returnNumber !== "") {
                        if (str.test(submitOBj.value.returnNumber)) {
                            params.returnNumber = submitOBj.value.returnNumber;
                        } else {
                            return ElMessage.error("请输入正确退货单号");
                        }
                    }
                    params.logisticsImage = imgArr.join(",");
                    if (AfterSaleType.value !== "仅退款") {
                        params.isOfflineMailing = isChecked.value == true ? 1 : 0;
                    }
                }

                console.log(params);
                params = JSON.stringify(params);
                proxy.$post("api/shopp/order/after/submit", params, "json").then((res) => {
                    //原接口：v3/business/front/after/sale/apply/submit
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        router.go(-1);
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            }
        };

        const handleBlur = () => {
            if (curEditRefundAmount.value.toString().split(".").length) {
                let tmp = curEditRefundAmount.value.toString().split(".")[0];
                curEditRefundAmount.value =
                    tmp.length > 2 ? curEditRefundAmount.value.toFixed(2) : curEditRefundAmount.value;
            }
        };

        const handleApply = () => {
            let reg = /\./g;
            let reg0 = /0+\d/;

            if (reg.test(applyNum.value) || reg0.test(applyNum.value) || applyNum.value <= 0) {
                applyNum.value = 1;
            }

            refundOrderDetail.data.currNum = applyNum.value;
            getRefundDetail(null, null, applyNum.value);
        };
        const onCheck = (value) => {
            console.log(2323);
            isChecked.value = value;

            if (isChecked.value == true) {
                submitOBj.value.returnNumber = "";
                itemTableData.data = [];
            }
        };

        const returnNumberChange = (value) => {
            if (value.trim() !== "") {
                onCheck(false);
                submitOBj.returnNumber = value;
            } else {
                onCheck(true);
            }
        };
        const beforeUpload = (file) => {
            const isImage = file.type.startsWith("image/");
            if (!isImage) {
                ElMessage({
                    message: "只能上传图片格式文件!",
                    type: "error"
                });
            }
            return isImage;
        };
        //** 上传图片
        const handlePictureCardSuccess = (row) => {
            return (res, file, fileList) => {
                let obj = {
                    uid: "",
                    name: "",
                    url: ""
                };
                if (res.code == 200) {
                    obj.uid = file.uid;
                    obj.name = file.name;
                    obj.url = res.msg;
                    itemAddImgData.data.push(obj);
                    //itemTableData.data.push(obj)
                }
                console.log(itemTableData.data.length);
                if (itemAddImgData.data.length == 5) {
                    console.log(29202);
                    document.getElementById("imgUpload").children[1].style.display = "none";
                }
            };
        };

        const handleRemoves = (uploadFile, uploadFiles) => {
            console.log(uploadFile, uploadFiles);
            itemDelImgData.data = uploadFiles;
            itemAddImgData.data = uploadFiles;
            itemTableData.data = uploadFiles;
            console.log(2222, itemDelImgData.data.length);

            if (itemTableData.data.length < 5) {
                document.getElementById("imgUpload").children[1].style.display = "inline-block";
            }
        };

        const handlePictureCardPreviews = (uploadFile) => {
            dialogImageUrl.value = uploadFile.url;
            dialogVisibleFull.value = true;
        };
        //关闭弹窗
        const handleCloseDesc = () => {
            descImgVisible.value = false;
            itemTableData.data = [...itemTableData.data];
        };
        //确认弹窗
        const handleConfirmDesc = () => {
            descImgVisible.value = false;
            if (itemDelImgData.data.length == 0 && itemAddImgData.data.length == 0 && itemTableData.data == 0) {
                itemTableData.data = [];
            } else if (itemDelImgData.data.length > 0) {
                for (let index = 0; index < itemDelImgData.data.length; index++) {
                    if (itemDelImgData.data[index].response) {
                        itemDelImgData.data[index].url = itemDelImgData.data[index].response.msg;
                    }
                }
                itemTableData.data = itemDelImgData.data;
            } else {
                itemTableData.data = [...itemTableData.data, ...itemAddImgData.data];
                console.log(itemTableData.data, "2822902");
            }

            if (itemTableData.data.length !== 0) {
                onCheck(false);
            } else {
                onCheck(true);
            }
        };
        const addDescImg = () => {
            itemDelImgData.data = [];
            itemAddImgData.data = [];
            //console.log(props.itemTableData.data);
            setTimeout(() => {
                descImgVisible.value = true;
            }, 1000);
            if (itemTableData.data.length == 5) {
                setTimeout(() => {
                    document.getElementById("imgUpload").children[1].style.display = "none";
                }, 1000);
            }
        };
        return {
            L,
            refundOrderDetail,
            cargoStatuslList,
            curCargoStatus,
            returnAmount,
            applyNum,
            refundDetail,
            cancelList,
            changeCancel,
            curCancelReason,
            changeCargoStatus,
            refundInstruction,
            uploadImgUrl,
            submitApplyRefund,
            serviceType,
            cargoStatusCurId,
            curEditRefundAmount,
            cancelEditAmount,
            okEditAmount,
            batchSelGoods,
            batchSelModel,
            applyTypeList,
            curApplyType,
            changeApplyType,
            batchList,
            defaultGoods,
            batchGoodsList,
            selGoods,
            batchCancel,
            batchOk,
            batchGoods,
            orderState,
            editAmountShow,
            editAmount,
            orderCode,
            dialogImageUrl,
            dialogVisible,
            handlePictureCardPreview,
            upload_data,
            uploadImg,
            upLoadFile,
            getCancelList,
            exceedHandle,
            handleRemove,
            handleBlur,
            handleApply,
            AfterSaleType,
            isChecked,
            onCheck,
            submitOBj,

            CheckrdImg,
            ellipse,
            returnNumberChange,
            itemAddImgData,
            itemDelImgData,
            uploadImgUrl,
            itemTableData,
            access_token,
            beforeUpload,
            dialogVisibleFull,
            dialogImageUrls,
            handleCloseDesc,
            handleConfirmDesc,
            handlePictureCardSuccess,
            handleRemoves,
            handlePictureCardPreviews,
            returnNumberChange,
            addDescImg,
            descImgVisible
        };
    }
};
</script>

<style lang="scss">
@import "../../../style/base.scss";
@import "../../../style/iconfont.css";
@import "../../../style/applyRefund.scss";

.el-radio__label {
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.el-icon-close-tip {
    display: none !important;
}

.el-upload-list__item-status-label {
    display: none !important;
}

.el-radio__input.is-checked .el-radio__inner {
    border-color: $colorMain2;
    background: $colorMain2;
}

.el-radio__input.is-checked + .el-radio__label {
    color: $colorMain2;
}

.el-textarea {
    width: 786px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.upload_count {
    font-size: 14px;
    margin-bottom: 10px;
}
.online {
    display: flex;
}
</style>
